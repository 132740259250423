export default {
  head() {
    const head = { meta: [], link: [] }

    const descriptionHidName = {
      hid: 'description',
      name: 'description'
    }
    const ogDescription = {
      hid: 'og:description',
      property: 'og:description'
    }
    const ogTitle = {
      hid: 'og:title',
      property: 'og:title'
    }
    const ogType = {
      hid: 'og:type',
      property: 'og:type'
    }

    // Vookキャリアサイト名
    if (this.$route.path.match(/\/career/)) {
      head.meta.push(
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Vookキャリア'
        },
        {
          hid: 'apple-mobile-web-app-title',
          property: 'apple-mobile-web-app-title',
          content: 'Vookキャリア'
        }
      )
    }

    // タイトル
    if (this.$route.path === '/career') {
      head.title =
        'Vookキャリア | 映像プロフェッショナル人材のためのキャリア支援サービス'
      head.titleTemplate = ''
      head.meta.push({
        ...ogTitle,
        content: 'Vookキャリア'
      })
    } else if (this.$route.path.match(/\/career\//) && this.meta.title) {
      const title = `${this.meta.title}`
      head.title = title
      head.titleTemplate = '%s | Vookキャリア'
      head.meta.push({ ...ogTitle, content: title })
    } else if (this.$route.path === '/school') {
      head.title = '動画編集・映像制作の学校 | Vook school (ヴックスクール)'
      head.titleTemplate = ''
      head.meta.push({
        ...ogTitle,
        content: 'Vook school (ヴックスクール)'
      })
    } else if (this.$route.path === '/businesses') {
      head.title = '事業紹介動画 制作講座 ｜ Vook biz for Education'
      head.titleTemplate = ''
      head.meta.push({
        ...ogTitle,
        content: 'Vook biz for Education'
      })
    } else if (this.$route.path.match(/\/businesses\//) && this.meta.title) {
      const title = `${this.meta.title}`
      head.title = title
      head.titleTemplate = '%s | Vook biz for Education'
      head.meta.push({ ...ogTitle, content: title })
    } else if (this.$route.path.match(/\/search\//)) {
      head.titleTemplate = ''
      if (this.$route.query.query) {
        head.title = `「${this.$route.query.query}」に該当する記事一覧 | 動画編集・映像制作Tipsサイト | Vook(ヴック)`
        if (this.$route.path === '/search/packages') {
          head.title = `「${this.$route.query.query}」に該当するチュートリアル学習 | 動画編集・映像制作Tipsサイト | Vook(ヴック)`
        } else if (this.$route.path === '/search/terms') {
          head.title = `「${this.$route.query.query}」に該当する用語集 | 動画編集・映像制作Tipsサイト | Vook(ヴック)`
        }
        head.meta.push({
          ...ogTitle,
          content: head.title
        })
      } else if (this.$route.query.tags) {
        head.title = `「${this.$route.query.tags}」に該当する記事一覧 | 動画編集・映像制作Tipsサイト | Vook(ヴック)`
        if (this.$route.path === '/search/packages') {
          head.title = `「${this.$route.query.tags}」に該当するチュートリアル学習 | 動画編集・映像制作Tipsサイト | Vook(ヴック)`
        } else if (this.$route.path === '/search/terms') {
          head.title = `「${this.$route.query.tags}」に該当する用語集 | 動画編集・映像制作Tipsサイト | Vook(ヴック)`
        }
        head.meta.push({
          ...ogTitle,
          content: head.title
        })
      }
    } else if (this.meta.title) {
      const title = `${this.meta.title}`
      head.title = title
      head.meta.push({ ...ogTitle, content: title })
    } else if (this.$route.path === '/') {
      head.title = 'Vook(ヴック) | 日本最大級の動画編集・映像制作Tipsサイト'
      head.titleTemplate = ''
      head.meta.push({
        ...ogTitle,
        content: 'Vook(ヴック)'
      })
    } else {
      head.title = 'Vook(ヴック)'
      head.titleTemplate = ''
      head.meta.push({
        ...ogTitle,
        content: 'Vook(ヴック)'
      })
    }

    // ディスクリプション
    if (this.meta.description) {
      // 画像URLが先頭と途中で途切れた場合とマークダウンの記述を削除
      this.meta.description = this.meta.description
        .replace(/!\[(.+?\)|.+)|\**\*|>\s|#*#|~~*~|\d{1,}\.|\s\/n\/\d{1,}/g, '')
        .trim()
      head.meta.push({
        ...descriptionHidName,
        content: this.meta.description
      })
      head.meta.push({
        ...ogDescription,
        content: this.meta.description
      })
    } else if (this.$route.path.match(/\/career/)) {
      const description =
        '映像プロフェッショナル人材のためのキャリア支援サービス'
      head.meta.push({
        ...descriptionHidName,
        content: description
      })
      head.meta.push({
        ...ogDescription,
        content: description
      })
    } else if (this.$route.path.match(/\/search\//)) {
      if (this.$route.query.query) {
        const contentQuery = {
          content: `動画編集・映像制作に関する、${this.$route.query.query}の業界動向やノウハウをまとめています。`
        }
        if (this.$route.path === '/search/packages') {
          contentQuery.content = `動画編集・映像制作に関する、${this.$route.query.query}のスキルを効率よく身につけるためのチュートリアルをご紹介します。`
        } else if (this.$route.path === '/search/terms') {
          contentQuery.content = `動画編集・映像制作で使用される、${this.$route.query.query}に関する用語をわかりやすく解説しています。`
        }
        head.meta.push({
          ...descriptionHidName,
          ...contentQuery
        })
        head.meta.push({
          ...ogDescription,
          ...contentQuery
        })
      } else if (this.$route.query.tags) {
        const contentTags = {
          content: `動画編集・映像制作に関する、${this.$route.query.tags}の業界動向やノウハウをまとめています。`
        }
        if (this.$route.path === '/search/packages') {
          contentTags.content = `動画編集・映像制作に関する、${this.$route.query.tags}のスキルを効率よく身につけるためのチュートリアルをご紹介します。`
        } else if (this.$route.path === '/search/terms') {
          contentTags.content = `動画編集・映像制作で使用される、${this.$route.query.tags}に関する用語をわかりやすく解説しています。`
        }
        head.meta.push({
          ...descriptionHidName,
          ...contentTags
        })
        head.meta.push({
          ...ogDescription,
          ...contentTags
        })
      }
    }

    // ページタイプ
    if (this.meta.type) {
      head.meta.push({
        ...ogType,
        content: this.meta.type
      })
    } else if (this.$route.path === '/') {
      head.meta.push({
        ...ogType,
        content: 'website'
      })
    }

    // ページURL
    const url = `https://vook.vc${this.$route.path}`
    head.meta.push({ hid: 'og:url', property: 'og:url', content: url })

    // OGP画像
    if (this.meta.image) {
      const imageUrl = this.meta.image.startsWith('http')
        ? this.meta.image
        : `https://vook.vc${this.meta.image}`
      head.meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: imageUrl
      })
      head.meta.push({
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageUrl
      })
    }

    // canonical
    // https://www.notion.so/vookinc/URL-f76082856cae41718d0253502f3c5883
    const canonicalUrl = new URL(process.env.BASE_URL)
    canonicalUrl.pathname = this.$route.path
    if (this.$route.path.startsWith('/search/')) {
      // サイト内検索（Search）時のクエリストパラメータ tags は統合対象としない
      const names = ['tags']
      names.forEach((name) => {
        const value = this.$route.query[name]
        if (value) {
          canonicalUrl.searchParams.append(name, value)
        }
      })
    }
    head.link.push({
      hid: 'canonical',
      rel: 'canonical',
      href: canonicalUrl.href
    })

    // body class
    if (this.bodyAttrs) {
      head.bodyAttrs = { class: this.bodyAttrs.class }
    }

    // 外部 js
    if (this.script) {
      head.script = this.script
    }

    // 外部 css
    if (this.link) {
      head.link = head.link.concat(this.link)
    }

    return head
  }
}
