import dayjs from './dayjs'

const rawCampaigns: Campaign[] = require('~/assets/json/campaign/campaigns.json')

/**
 * キャンペーン全件
 *
 * * 最終更新日 or 公開日順にソートする
 * * 同じ日付の場合は、idが大きい順(新しいキャンペーン順)に表示する
 * * 終了案件は後ろに並べる
 */
export const campaigns = rawCampaigns.sort((a, b) => {
  if (a.end !== b.end) {
    return Number(a.end) - Number(b.end)
  }

  const timestampA = getTimestamp(a)
  const timestampB = getTimestamp(b)
  if (timestampA === timestampB) {
    return b.id - a.id
  }

  return timestampB - timestampA
})

function getTimestamp(campaign: Campaign): number {
  const publishedAt = dayjs(campaign.published_at).startOf('day')
  const updatedAt = dayjs(campaign.updated_at).startOf('day')

  return updatedAt.isAfter(publishedAt) ? updatedAt.unix() : publishedAt.unix()
}
