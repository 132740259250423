


























import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
// @ts-ignore
import dayjs, { getDateString } from '~/libs/dayjs'
import { convertAssetImage } from '~/libs/imagepix'

export default defineComponent({
  props: {
    campaign: {
      type: Object as PropType<Campaign>,
      required: true
    },
    // hooper drag時のクリック無効にする
    hooperActiveCampaign: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const campaignPublished = computed(
      () => dayjs(props.campaign.published_at) < dayjs() // diffだと指定時刻と指定時刻の1分前のdiffがどちらも0になってしまい条件が複雑になってしまうため、nowと比較する
    )
    const isPublishedIn14Days = computed(
      () => dayjs().diff(props.campaign.published_at, 'days') < 14
    )
    const isUpdatedIn14Days = computed(
      () => dayjs().diff(props.campaign.updated_at, 'days') < 14
    )
    const publishedAt = computed(() =>
      getDateString(props.campaign.published_at)
    )
    const updatedAt = computed(() => getDateString(props.campaign.updated_at))

    return {
      campaignPublished,
      isPublishedIn14Days,
      isUpdatedIn14Days,
      publishedAt,
      updatedAt,
      convertAssetImage
    }
  }
})
